<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mt-2">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="selectedOrganizationBranch" :clearable="false"
                                  :options="organizationBranchOptions">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-2">
                        <label class="vs-input--label">Loại thông báo</label>
                        <v-select class="" v-model="announcementTypeModel" :clearable="false"
                                  :options="NotifyTypeOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="leaveType"/>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mt-4" v-if="isShowOption">
                        <label class="vs-input--label">Nhân viên</label>
                        <multiselect
                            name="employeeIds"
                            data-vv-validate-on="input"
                            v-model="newsInfo.employeeIds"
                            :options="employeeOptions"
                            :searchable="true"
                            :multiple="true"
                            :close-on-select="false"
                            :allow-empty="true"
                            :clearable="false"
                            placeholder="Chọn nhân viên"
                            label="fullName"
                            track-by="id"
                            v-validate="'required'"
                        >
                        </multiselect>
                        <span class="text-danger text-sm">{{ errors.first('Nhân viên') }}</span>
                    </div>
                </div>
                <div class="vx-row mt-4">
                    <div class="vx-col md:w-1/2 w-full">
                        <vs-input class="w-full" label="Tiêu đề" placeholder="Nhập tiêu đề" v-model="newsInfo.title"
                                  v-validate="'required'" name="title"/>
                        <span class="text-danger text-sm">{{ errors.first('title') }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full">
                        <vs-input class="w-full" label="Tóm tắt" placeholder="Nhập tóm tắt" v-model="newsInfo.summary"
                                  name="content"/>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col md:w-1/1 w-full mt-5">
                        <label class="vs-input--label">Nội dung bài viết</label>
                        <quill-editor class="w-full input-content" placeholder="Nhập nội dung" v-model="newsInfo.content"
                                     name="content"/>
                    </div>
                </div>

                <br>
                <br>

                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <vs-button class="ml-auto mt-2" @click="saveChanges"
                                       :disabled="!validateForm">{{
                                    `${'Tạo mới'}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="leaveList">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>

import vSelect from 'vue-select'
import utils from "../../../components/utils";
import Multiselect from 'vue-multiselect';
// import moment from 'moment';
import 'quill/dist/quill.snow.css';
import {quillEditor} from 'vue-quill-editor'

export default {
    components: {
        vSelect,
        utils,
        Multiselect,
        quillEditor
    },
    data() {
        return {
            isUpdate: false,
            getValueEmployee: [],
            employeeOptions: [],
            organizationBranchOptions: [],
            selectedOrganizationBranchValue: null,
            isShowOption: false,
            title: '',
            content: '',
            fullName: '',
            summary: '',
            NotifyTypeOptions: [
                {value: 0, label: 'Gửi tất cả'},
                {value: 1, label: 'Gửi từng người'},
            ],
            uploadPercent: 0,
            isShowPercent: false,
            newsInfo: {},
            selectedEmployeeValue: null
        }
    },
    created() {
        this.initScreen();
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });
        this.newsInfo = {
            announcementType: 0,
            content: '',
            employeeIds: [],
            organizationBranchId: 0,
            summary: '',
            title: ''
        };
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateEmployee'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_EMPLOYEE', false);
            }
        }
    },
    computed: {
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj.value
                this.loadEmployee()
            }
        },

        announcementTypeModel: {
            get() {
                return {
                    label: this.getLabelString(this.NotifyTypeOptions.find(x => x.value == this.newsInfo.announcementType)),
                    value: this.newsInfo.announcementType
                }
            },
            set(obj) {
                this.newsInfo.announcementType = obj.value;
                if (obj && obj.value == 1) {
                    this.isShowOption = true
                } else {
                    this.isShowOption = false
                }
            }
        },

        employeeIds: {
            get() {
                return {
                    label: this.employeeString(this.employeeIds),
                    value: this.newsInfo.employeeIds
                }
            },
            set(obj) {
                this.newsInfo.employeeIds = obj.value;
            }
        },
        validateForm() {
            return !this.errors.any()
        },
    },
    methods: {
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || 'Chưa xác định';
        },
        successUpload() {
            this.$vs.notify({
                color: 'success',
                title: 'Upload Success',
                text: 'Lorem ipsum dolor sit amet, consectetur'
            })
        },
        onOptionClick({option, addTag}) {
            addTag(option)
            this.search = ''
        },
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
            if (branchList.length === 0) {
                return this.$vs.notify({
                    text: 'Hiện tại không tồn tại chi nhánh nào',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            });
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];

        },
        loadEmployee() {
            if (!this.selectedOrganizationBranchValue) return;
            this.$vs.loading();
            this.$crm.post('/employee/find-by-organization-branch/' + this.selectedOrganizationBranchValue).then((response) => {
                this.employeeData = response.data;
                this.employeeOptions = response.data.map(item => {
                    return {
                        employeeCode: item.employeeCode,
                        fullName: item.fullName,
                        id: item.employeeId,
                    }
                });
                // this.employeeOptions.unshift({value: null, label: 'Tất cả'})
                this.filter();
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
            // this.$vs.loading();
            // this.$crm.get('announcement/find-by-id?announcementId=' + this.$route.query.announcementId)
            //     .then((response) => {
            //         if (response.data) {
            //             this.isUpdate = true;
            //             this.newsInfo = response.data;
            //             this.announcementType = this.NotifyTypeOptions[response.data.announcementType];
            //             this.employeeDetailResponseDTOS = this.employeeIds;
            //         }
            //         this.$vs.loading.close();
            //     })
            //     .catch((err) => {
            //         this.$vs.loading.close();
            //         return this.$vs.notify({
            //             text: err.response.data.message || err.response.data.error_description || err.response.data.error,
            //             iconPack: 'feather',
            //             icon: 'icon-alert-circle',
            //             color: 'danger'
            //         });
            //     });
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;

                let url = `/announcement/create`,

                    data = {

                        announcementType: this.newsInfo.announcementType,
                        content: this.newsInfo.content,
                        employeeIds: this.newsInfo.employeeIds.map(k =>
                            k.id
                        ),
                        organizationBranchId: this.selectedOrganizationBranchValue,
                        summary: this.newsInfo.summary,
                        title: this.newsInfo.title,
                    };

                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.loading.close();
                    this.leaveList();
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} thành công`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        leaveList() {
            this.$router.push(`/user/manage-notification${this.newsInfo.organizationBranchId ? `?branch_id=${this.newsInfo.organizationBranchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>

<style scoped>
.input-content{
    height: 200px !important;
}
</style>
